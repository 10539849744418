import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  betAmountCs: {
    color: theme.palette.text?.default,
  },
  logoSize: {
    width: "60px",
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  fadeIn: {
    animation: "$fadeIn 0.5s ease-in",
  },

  referralText: {
    maxWidth: "278px",
    [theme.breakpoints.down(991)]: {
      maxWidth: "190px",
    },
  },

  bonusSectionAlign: {
    margin: "0",
    width: "100%",
  },

  semiBannerImage: {
    position: "relative",
    "& img": {
      width: "100%",
    },
  },

  BannerImageTopAlign: {
    "& img": {
      marginTop: "16px",
    },
  },

  bonusHeading: {
    display: "flex",
    gap: "14px",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "26px",
    lineHeight: "32px",
    textTransform: "uppercase",
    color: "#FFFFFF",
    padding: "24px 0",
    "& img": {
      width: "20px",
    },
  },

  semiBanner: {
    "& react-multi-carousel-item react-multi-carousel-item--active ": {
      width: "597px",
      [theme.breakpoints.down(991)]: {
        width: "100%",
      },
    },
  },

  semiBannerImageText: {
    top: "0",
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "0 auto",
    height: "100%",
    left: "55px",
    [theme.breakpoints.down(1100)]: {
      left: "20px",
    },

    [theme.breakpoints.down(991)]: {
      left: "25px",
    },
    "& h5": {
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "26px",
      lineHeight: "32px",
      textTransform: "uppercase",
      color: "#FFFFFF",
      [theme.breakpoints.down(1100)]: {
        fontSize: "22px",
        lineHeight: "20px",
      },
      [theme.breakpoints.down(991)]: {
        fontSize: "16px",
      },
    },
    "& p": {
      marginTop: "16px",
      marginBottom: "24px",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "160%",
      color: "#FFFFFF",
      [theme.breakpoints.down(1745)]: {
        marginBottom: "10px",
        marginTop: "10px",
        fontSize: "16px",
      },

      [theme.breakpoints.down(1100)]: {
        fontSize: "14px",
      },

      [theme.breakpoints.down(991)]: {
        fontSize: "14px",
        marginTop: "0px",
        marginBottom: "10px",
      },
    },
    "& button": {
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      border: "2px solid rgba(106, 113, 199, 0.84)",
      boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
      borderRadius: "8px !important",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#FFFFFF",
      minWidth: "160px",
      maxWidth: "160px",
      [theme.breakpoints.down(1100)]: {
        fontSize: "14px",
        borderRadius: "4px !important",
        padding: "4px !important",
        maxWidth: "100px",
        minWidth: "100px",
      },
    },
  },

  providerName: {
    width: "100%",
  },

  bannerImage: {
    position: "relative",
    height: "100%",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    marginBottom: "0px",
    "& img": {
      width: "100%",
      height: "550px",
      objectFit: "cover",
      borderRadius: "20px",
    },
    [theme.breakpoints.down("md")]: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      height: "215.7px",
      borderRadius: "10px",
      marginBottom: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "192.11px",
      borderRadius: "10px",
    },
  },
  bannerImage2: {
    position: "relative",
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    backgroundSize: "cover",
    display: "flex",
    alignItems: "center",
    borderRadius: "20px",
    marginTop: "20px",
    "& img": {
      width: "100%",
      height: "550px",
      objectFit: "cover",
      borderRadius: "20px",
      [theme.breakpoints.down("sm")]: {
        height: "215.7px",
        objectFit: "cover",
        borderRadius: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "192.11px",
        borderRadius: "10px",
      },
    },
    [theme.breakpoints.down("md")]: {
      backgroundRepeat: "no-repeat",
      backgroundPosition: "right",
      backgroundSize: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      height: "215.7px",
      borderRadius: "10px",
      margin: "0px!important",
      marginBottom: "12px !important",
    },
    [theme.breakpoints.down("xs")]: {
      height: "192.11px",
      borderRadius: "10px",
    },
  },
  bannerImageCarousel: {
    width: "100%",
    height: "260px",
    backgroundImage: "url('/images/referfriend.png')",
    marginBottom: "0",
    backgroundSize: "contain",
  },

  position: {
    position: "relative",
  },
  position2: {
    position: "relative",
    width: "378px",
  },

  imageText: {
    position: "absolute",
    right: "40px",
    top: "0",
    bottom: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  textWidth: {
    maxWidth: "130px",
    textAlign: "right",
  },

  bannerText: {
    display: "flex",
    position: "absolute",
    left: "0",
    right: "0",
    textAlign: "center",
    flexDirection: "column",
    top: "120px",
    [theme.breakpoints.down(1536)]: {
      top: "40px",
    },
    "& h2": {
      fontWeight: "800",
      fontSize: "42px",
      lineHeight: "32px",
      textTransform: "uppercase",
      color: theme.palette.text?.default,
      [theme.breakpoints.down(1536)]: {
        fontSize: "18px",
      },
      "& span": {
        color: "#00daea",
      },
    },
    "& p": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "26px",
      lineHeight: "160%",
      textAlign: "center",
      color: "#757786",
      margin: "40px 0",

      [theme.breakpoints.down(1536)]: {
        fontSize: "13px",
        marginTop: "10px",
        marginBottom: "10px",
      },
      [theme.breakpoints.down(991)]: {
        fontSize: "14px",
        marginTop: "10px",
        marginBottom: "20px",
      },
      "& span": {
        color: "#00daea",
      },
    },
    "& button": {
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      border: "2.4px solid rgba(106, 113, 199, 0.84)",
      boxShadow: "0px 4px 24px 2px rgba(108, 93, 193, 0.44)",
      borderRadius: "12px !important",
      fontStyle: "normal",
      fontWeight: "800",
      fontSize: "18px",
      lineHeight: "22px",
      textTransform: "capitalize",
      color: "#FFFFFF",
      minWidth: "300px !important",
      maxWidth: "300px !important",
      margin: "0 auto",
      padding: "17px 0 !important",
      [theme.breakpoints.down(1536)]: {
        padding: "7px 0 !important",
        fontSize: "14px",
        maxWidth: "160px !important",
        minWidth: "160px !important",
        borderRadius: "4px !important",
      },
    },
  },

  containerWraper: {
    flexWrap: "nowrap",
    gap: "10px",
  },

  btnWrap: {
    margin: "10px 0",
    display: "block",
  },
  btnPrimary: {
    background: "linear-gradient(270deg, #7458DC 0%, #9787D2 100%)",
    borderRadius: "30px",
    padding: "6px 30px",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "600",
    textTransform: "capitalize",
    transition: "background 400ms ease-in-out",
  },
  btnSecondary: {
    background: theme.palette?.background?.highlightSocialApp,
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "23px",
    display: "flex",
    alignItems: "center",
    color: theme.palette?.background?.seeAll,
    padding: "5px 16px",
    textDecoration: "none",
    marginRight: "80px",
    height: "33px",
    minWidth: "80px",
    [theme.breakpoints.down(1028)]: {
      padding: "0px 10px",
      fontSize: "14px",
      marginRight: "60px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 12px",
      fontSize: "16px",
      marginBottom: "0",
      borderRadius: "6px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "26px",
      minWidth: "66px",
      justifyContent: "center",
    },
    "&:hover": {
      background: "rgba(43, 43, 56, 0.8)",
      color: theme.palette?.background?.seeAll,
      opacity: " 0.8",
    },
  },
  bannerContent: {
    padding: "30px 0 30px 60px",
    "& .MuiTypography-h1": {
      fontWeight: "bold",
      fontSize: "47px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "37px",
      },
      "& br": {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },
    "& img": {
      marginRight: "5px",
      [theme.breakpoints.down("sm")]: {
        marginRight: "0",
        width: "100px",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: "32px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
    "& .MuiButton-textPrimary": {
      padding: "6px 40px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 15px",
    },
  },
  homeFilter: {
    justifyContent: "space-between",
    padding: "30px 200px",
    [theme.breakpoints.down("md")]: {
      padding: "30px 100px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 15px",
      overflowX: "auto",
    },
    "& .MuiInputBase-colorPrimary": {
      width: "333px",
      background: "#7458DC",
      borderRadius: "10px",
      color: "#000",
      margin: "20px",
      [theme.breakpoints.down("md")]: {
        marginRight: "10px",
        width: "100px",
        fontSize: "14px",
      },
    },
    "& .MuiInputBase-input": {
      height: "20px",
      padding: "10px",
      background: "#7458DC",
      borderRadius: "10px",
      fontSize: "14px",
      "&::placeholder": {
        color: "#4D4D5B",
        fontWeight: "500",

        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
        [theme.breakpoints.down("sm")]: {
          color: "#73737e",
          fontSize: "16px!important",
          fontFamily: "Jost!important",
          fontWeight: "500",
        },
      },
      [theme.breakpoints.down("md")]: {
        width: "150px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
    "& .MuiSvgIcon-root": {
      color: "#000",
    },
  },

  casinoCard: {
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
    borderRadius: "10px",
    height: "200px",
    aspectRatio: "0.8",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "152px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "122px",
    },
    "& .css-vj1n65-MuiGrid-root": {
      height: "100%",
    },

    "& img": {
      height: "100%",
      width: "100%",
      borderTop: theme?.palette?.background?.gameTopBorder,
      borderRadius: "12px",
      objectFit: "cover",
    },
    "& .MuiTypography-body1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        overflowX: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "120px",
      },
    },
    "&:hover": {
      "& .MuiBox-root": {
        opacity: "1",
      },
    },
  },
  casinoOverlay: {
    marginTop: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-body1": {
      paddingRight: "10px",
      fontWeight: " 500",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: theme?.palette?.text?.default,
      width: "100%",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "normal",
      },
    },
  },

  customInfoModal: {
    borderRadius: "10px",
    background: "#6201A6",
    position: "absolute",
    minWidth: "270px",
    left: "-52px",
    right: "auto",
    top: "-100px",
    padding: "5px",
    zIndex: "5",
    display: "none",
    transform: "translate(-50%, -50%)",
    [theme.breakpoints.down("sm")]: {
      left: "-132px",
    },
  },
  customInfoCard: {
    padding: "10px",
    background: "#480279",
    margin: "5px",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& span": {
      fontSize: "14px",
      color: "#bbb7b7",
    },
    "& .MuiTypography-body1": {
      fontSize: "16px",
      color: "#fff",
    },
  },
  innerHeading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  innerHeadingLeft: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "15px",
      width: "20px",
      [theme.breakpoints.down("md")]: {
        marginRight: "8px",
        width: "15px",
      },
    },
    "& .MuiTypography-h6": {
      fontSize: "20px",
      lineHeight: "29px",
      textTransform: "capitalize",
      color: theme.palette?.text?.default,
      margin: "0",
      [theme.breakpoints.down(1200)]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down(1028)]: {
        fontSize: "16px",
      },
    },
  },
  noGamesMsg: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
      margin: "0",
      padding: "10px 10px 30px",
      color: "#4D4D5B",
      width: "100%",
      textAlign: "center",
    },
  },
  categoryLoader: {
    margin: "40px",
  },
  homePromotionCard: {
    "& img": {
      width: "100%",
    },
  },
  filterWrapCenter: {
    display: "flex",
    alignItems: "center",
    background: theme.palette?.background?.sidebar,
    boxShadow: "inset 0px 2px 4px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px",
    // overflowX: "auto",
    margin: "40px 0 !important",
    border: "1.4px solid rgb(255 255 255 / 4%)",
    padding: "12px 20px !important",
    [theme.breakpoints.down("md")]: {
      padding: "10px !important",
    },
    [theme.breakpoints.down("sm")]: {
      // padding: "8px 10px !important",
      margin: "0px 0 10px!important",
      display: "block",
      background: "transparent",
      padding: " 0 !important",
    },
  },

  selectBoxWrap: {
    "& .MuiButtonBase-root.Mui-disabled": {
      display: "none",
      opacity: "0",
    },
    "& .MuiTabs-flexContainer": {
      display: "-webkit-box !important",
    },
    margin: "40px 0 42px!important",
    [theme.breakpoints.down("md")]: {
      margin: "20px 0 10px!important",
    },
    "& .MuiInputBase-root": {
      width: "100%",
    },

    "& fieldset": {
      border: "none",
    },

    "& .MuiSelect-select": {
      padding: "3px 14px",
      fontSize: "16px",
    },
    "& .MuiMenu-paper": {
      left: "10px !important",
      minWidth: "calc(100% - 20px) !important",
    },

    "& .react-horizontal-scrolling-menu--wrapper": {
      maxWidth: "100% !important",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    "& .react-horizontal-scrolling-menu--scroll-container": {
      height: "230px",
      gap: "9px",
      [theme.breakpoints.down("md")]: {
        height: "230px",
        gap: "5px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "190px",
        gap: "3px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "160px",
        gap: "3px",
      },
    },
  },

  filterWrap: {
    "& .MuiButtonBase-root.Mui-disabled": {
      display: "none",
      opacity: "0",
    },
    "& .MuiTabs-flexContainer": {
      display: "-webkit-box !important",
    },
    margin: "0px 0 42px!important",
    [theme.breakpoints.down("md")]: {
      margin: "0px 0 10px!important",
    },
    "& .MuiInputBase-root": {
      width: "100%",
    },

    "& fieldset": {
      border: "none",
    },

    "& .MuiSelect-select": {
      padding: "3px 14px",
      fontSize: "16px",
    },
    "& .MuiMenu-paper": {
      left: "10px !important",
      minWidth: "calc(100% - 20px) !important",
    },

    "& .react-horizontal-scrolling-menu--wrapper": {
      maxWidth: "100% !important",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    "& .react-horizontal-scrolling-menu--scroll-container": {
      height: "230px",
      gap: "9px",
      [theme.breakpoints.down("md")]: {
        height: "230px",
        gap: "5px",
      },
      [theme.breakpoints.down("sm")]: {
        height: "190px",
        gap: "3px",
      },
      [theme.breakpoints.down("xs")]: {
        height: "160px",
        gap: "3px",
      },
    },

    "& .react-horizontal-scrolling-menu--arrow-right": {
      position: "absolute",
      right: "0px",
      top: "0",
      transform: "translateY(-53px)",
      [theme.breakpoints.down("xl")]: {
        transform: "translateY(-53px)",
      },

      [theme.breakpoints.down(1028)]: {
        transform: "translateY(-43px)",
      },

      [theme.breakpoints.down(991)]: {
        transform: "translateY(-38px)",
      },
    },
    "& .react-horizontal-scrolling-menu--arrow-left": {
      position: "absolute",
      right: "40px",
      top: "0",
      transform: "translateY(-53px)",
      [theme.breakpoints.down("xl")]: {
        transform: "translateY(-53px)",
      },

      [theme.breakpoints.down(1028)]: {
        transform: "translateY(-43px)",
        right: "30px",
      },

      [theme.breakpoints.down(991)]: {
        transform: "translateY(-38px)",
      },
    },

    "& .react-horizontal-scrolling-menu--item": {
      height: "200px",
      width: "155px",
      aspectRatio: "1",
      [theme.breakpoints.down("md")]: {
        width: "32.15%",
        height: "182px",
        aspectRatio: "2",
      },
      [theme.breakpoints.down("sm")]: {
        width: "32.15%",
        height: "152px",
        aspectRatio: "2",
      },
      [theme.breakpoints.down("xs")]: {
        width: "32.15%",
        height: "122px",
        aspectRatio: "2",
      },
      "& .MuiGrid-item": {
        height: "100%",
      },
    },
  },

  filterWrapSlider: {
    position: "relative",
    marginBottom: "40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 0 0px 0 !important",
      padding: "0 !important",
    },
  },
  filterWrapSliderOverride: {
    "& .react-horizontal-scrolling-menu--wrapper": {
      "&:after": {
        display: "none",
      },
    },
  },

  leftArrow: {
    background: "#2B2B38 !important",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "8px !important",
    height: "33px",
    width: "33px",
    minWidth: "33px !important",
    padding: "0 !important",
    display: "flex !important",
    opacity: "1 !important",
    [theme.breakpoints.down(1028)]: {
      height: "25px",
      width: "25px",
      minWidth: "25px !important",
    },
    "& svg": {
      fill: "#666773",
      [theme.breakpoints.down("md")]: {
        width: "15px",
      },
      [theme.breakpoints.down(1028)]: {
        width: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "26px",
      width: "26px",
      minWidth: "26px !important",
      borderRadius: "6px!important",
    },
    "&:hover": {
      backgroundColor: "rgba(43, 43, 56, 0.8)",
    },
  },
  rightArrow: {
    background: "#2B2B38 !important",
    border: "1.4px solid rgba(255, 255, 255, 0.04)",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "8px !important",
    height: "33px !important",
    width: "33px !important",
    minWidth: "33px !important",
    padding: "0 !important",
    [theme.breakpoints.down(1028)]: {
      height: "25px",
      width: "25px",
      minWidth: "25px !important",
    },
    "& svg": {
      fill: "#666773",
      [theme.breakpoints.down("md")]: {
        width: "15px",
      },
      [theme.breakpoints.down(1028)]: {
        width: "15px",
      },
    },
    [theme.breakpoints.down("md")]: {
      height: "26px",
      width: "26px",
      minWidth: "26px !important",
      borderRadius: "6px!important",
    },
    "&:hover": {
      backgroundColor: "rgba(43, 43, 56, 0.8)",
    },
  },

  active: {
    background: `${theme?.palette?.background?.colorbutton} !important`,
    border: "1.4px solid rgba(255, 255, 255, 0.04) !important",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px !important",
    color: "#fff",
    "& img": {
      filter: "brightness(1)",
    },
  },

  filterWrapLeft: {
    textDecoration: "none",
    color: theme?.palette?.text?.filterbtn,
    display: "flex",
    alignItems: "center",
    padding: "0",
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "capitalize",
    border: "2px solid transparent",
    lineHeight: "40px",
    whiteSpace: "nowrap",
    "&:hover": {
      background: theme?.palette?.text?.fliterbtnhover,
      border: "2px solid rgba(255, 255, 255, 0.04)",
      boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
      borderRadius: "12px !important",
      color: theme?.palette?.text?.default,
      opacity: "1",
      "& button": {
        color: theme?.palette?.text?.default,
      },
    },
    "& button": {
      padding: "0 !important",
      fontWeight: "500",
      textTransform: "capitalize",
      opacity: "1",
      color: theme?.palette?.text?.buttoncolor,
      fontSize: "16px",
      minWidth: "inherit",
      maxWidth: "inherit",
    },
    "& img": {
      marginRight: "8px",
      width: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "16px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "1px 15px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("lg")]: {
      overflowX: "auto",
      whiteSpace: "nowrap",
      marginRight: "15px",
      height: "54px",
    },
    "& .tab-icon-title": {
      display: "flex",
      alignItems: "center",
      padding: "0 16px",
      fontSize: "14px",
    },
  },

  hrOrignalsSection: {
    display: "inline-block",
    width: "100%",
  },

  themeCard: {
    background: theme?.palette?.background?.tablebackground,
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
    borderRadius: "12px",
    position: "relative",
    margin: "40px 0 80px",

    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      margin: "70px 0",
    },
    [theme.breakpoints.down(991)]: {
      display: "none",
    },
  },
  hrOrignalsImg: {
    minHeight: "250px",
    backgroundImage: "url('/images/crash-card.png')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: "20px",
    backgroundPosition: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      backgroundPosition: "68% 40%",
    },
  },
  hrOrignalsContent: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "column",
    alignItems: "flex-start",
    padding: "40px 0 40px 30px",
    [theme.breakpoints.down("md")]: {
      paddingTop: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      transform: "translate(-50%, -50%)",
      top: "50%",
      left: "50%",
      position: "absolute",
      width: "100%",
      padding: "0",
    },
    "& .MuiTypography-h4": {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "52px",
      fontStyle: "italic",
      [theme.breakpoints.down("md")]: {
        fontSize: "30px",
        marginBottom: "10px",
      },
    },
    "& .MuiTypography-h5": {
      fontSize: "27px",
      [theme.breakpoints.down("md")]: {
        fontSize: "18px",
        marginBottom: "10px",
      },
    },
  },
  themeHeading: {
    background: "#1C1E22",
    padding: "8px 30px 5px",
    display: "flex",
    alignItems: "center",
    borderTopLeftRadius: "30px",
    borderTopRightRadius: "30px",
    position: "absolute",
    top: "-44px",
    left: "0",
    [theme.breakpoints.down("sm")]: {
      padding: "8px 30px 5px",
    },
    "& img": {
      width: "40px",
      marginLeft: "-10px",
    },
    "& .MuiTypography-body1": {
      fontSize: "18px",
      marginLeft: "5px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        marginLeft: "5px",
      },
    },
  },

  legend: {
    position: "relative",
    paddingTop: "45px",
    paddingLeft: "52px",
    maxWidth: "520px",
    [theme.breakpoints.down("xl")]: {
      maxWidth: "320px",
    },

    [theme.breakpoints.down("lg")]: {},

    [theme.breakpoints.down("md")]: {
      paddingTop: "0px",
    },

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
    },

    "& h6": {
      color: "#FFF",
      margin: "0",
      fontSize: "34px",
      fontStyle: "normal",
      [theme.breakpoints.down("xl")]: {
        fontSize: "27px",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "17px",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "27px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        textTransform: "uppercase",
      },
    },
    "& p": {
      color: "#FFF",
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "140%",
      margin: "0",
      padding: "25px 0px 40px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "18px",
        padding: "10px 0",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        padding: "0",
      },
      [theme.breakpoints.down("md")]: {
        padding: "10px 0 20px",
        fontSize: "17px",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "6px 0 12px",
        maxWidth: "72%",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "78%",
      },
      "& span": {
        color: "#00DAEA",
      },
    },
    "& button": {
      borderRadius: "12px",
      border: "2px solid rgba(106, 113, 199, 0.84)",
      background: theme.palette?.background?.bannerIcon,
      color: theme.palette?.background?.proColor,
      fontSize: "22px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      textTransform: "capitalize",
      padding: "14px 75px",
      cursor: "no-drop",
      fontFamily: "Jost !important",
      "&:hover": {
        background: theme.palette?.background?.bannerIcon,
        color: theme.palette?.background?.proColor,
        opacity: "1",
      },
      [theme.breakpoints.down("xl")]: {
        fontSize: "18px",
        padding: "10px 45px",
        borderRadius: "8px !important",
      },
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        padding: "8px 25px",
        borderRadius: "8px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "17px",
        padding: "10px 45px",
        border: "1.4px solid rgba(106, 113, 199, 0.84)",
        background:
          "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
        boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "6px 18px",
        borderRadius: "8px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        padding: "5px 12px",
        borderRadius: "8px !important",
      },
    },
  },

  legendCarousel: {
    paddingTop: "0px",
    paddingLeft: "54px",
    "& h6": {
      fontSize: "24px",
      textTransform: "uppercase",
      fontWeight: "800",
      lineHeight: "32px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px !important",
      },
    },

    "& p": {
      fontSize: "18px",
      width: "278px",
      padding: "16px 0 24px !important",
      [theme.breakpoints.down("sm")]: {
        padding: "6px 0 12px !important",
        fontSize: "12px",
      },
    },
    "& button": {
      padding: "8px 45px 12px 45px",
      fontSize: "16px",
      boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
      borderRadius: "8px",
      height: "40px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
    },
  },

  lobbysearchInput: {
    position: "relative",
    "& svg": {
      top: "7px",
      right: "20px",
      opacity: "0.5",
      position: "absolute",
      width: "15px",
    },
  },

  filterWrapRight: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    "& .css-1u3bzj6-MuiFormControl-root-MuiTextField-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    "& .MuiInputBase-input": {
      background: theme?.palette?.background?.default2,
      color: theme?.palette?.text?.default,
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px",
      height: "40px",
      minWidth: "240px",
      padding: "0 16px 0 22px",
      fontSize: "14px",
      fontWeight: "400",
      marginRight: "8px",
      "& ::placeholder": {
        color: "#4D4D5B",
        fontWeight: "400",
        [theme.breakpoints.down("md")]: {},
        [theme.breakpoints.down("sm")]: {
          color: "#73737e",
          fontSize: "16px!important",
          fontFamily: "Jost!important",
          fontWeight: "500",
        },
      },
      [theme.breakpoints.down("md")]: {
        // minWidth: "100%",
        marginRight: "0",
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "20px",
        borderRadius: "8px",
        background: "#1D1D23",
        boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.12) inset",
        height: "40px",
        color: "#4D4D5B",
        fontSize: "16px!important",
        fontFamily: "Jost!important",
        padding: "0px 16px",
      },
      "&::-webkit-input-placeholder": {},
    },
    "& .MuiInputBase-root": {
      "&:after, &:before": {
        display: "none",
      },
    },
    "& img": {
      position: "absolute",
      right: "20px",
      top: "10px",
    },
  },
  formGroup: {
    position: "relative",
  },
  currenciesSection: {
    marginBottom: "100px",
  },
  currencyListing: {
    background: "#1E2024",
    borderRadius: "20px",
    "& ul": {
      display: "flex",
      justifyContent: "space-between",
      "& li": {
        width: "100%",
        display: "flex",
        padding: "20px",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRight: " 2px solid #191A1E",

        "&:last-child": {
          border: "none",
        },
        "& img": {
          marginRight: "20px",
          [theme.breakpoints.down("sm")]: {
            width: "40px",
          },
        },
        "& .MuiTypography-h6": {
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
          },
        },
      },
    },
    [theme.breakpoints.down("lg")]: {
      overflowX: "auto",
    },
  },

  tableResponsive: {
    borderRadius: "20px",
    [theme.breakpoints.down("767")]: {
      overflowX: "auto",
    },
  },

  latestBetSection: {
    "& table": {
      width: "100%",
      borderCollapse: "collapse",
      tableLayout: "fixed",
      [theme.breakpoints.down("xl")]: {
        tableLayout: "inherit",
      },
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
      "& .liveUser": {
        background: theme.palette.background?.userImgBorder,
      },
      "& .userName": {
        color: theme.palette.text?.default,
      },
      "& thead": {
        borderRadius: "12px",
        "& th": {
          padding: "20px",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "20px",
          textTransform: "capitalize",
          color: "#757786",
          "&:nth-child(1)": {
            paddingLeft: "35px",
          },

          "&:nth-child(2)": {
            paddingRight: "0px",
            paddingLeft: "0px",
          },
          "&:nth-child(3)": {
            paddingLeft: "0px",
          },
          "&:nth-child(4)": {
            paddingLeft: "45px",
          },
          "&:nth-child(5)": {
            paddingLeft: "70px",
          },
        },
      },
      "& tbody": {
        "& td": {
          padding: "2px",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "20px",
          color: "#757786",
          "&:nth-child(1)": {
            paddingLeft: "35px",
          },
          "& span": {
            fontWeight: "normal",
            display: "flex",
            alignItems: "center",
          },
          "& b": {
            fontWeight: "normal",
          },
        },
        "& tr": {
          "&:nth-child(odd)": {
            background: theme?.palette?.background?.tablerow,
          },
          "&:last-child": {
            "& td": {
              "&:first-child": {
                borderRadius: "0 0 0 12px",
              },
              "&:last-child": {
                borderRadius: "0  0 12px 0",
              },
            },
          },
        },
      },
    },
  },

  latestBetSectionTarnsection: {},

  mobTabSection: {
    padding: "15px 10px 20px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "13px 10px 20px 10px",
    },
    "& button": {
      minWidth: "inherit !important",
      maxWidth: "inherit",
      padding: "0 !important",
      margin: "0 !important",
      minHeight: "inherit",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px !important",
      lineHeight: "18px",
      textTransform: "capitalize",
      color: theme?.palette?.text?.color,
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px !important",
        lineHeight: "15px",
      },
    },
    "& .Mui-selected": {
      background: theme?.palette?.background?.buttonbackground,
      border: theme?.palette?.background?.balanceBOrder,
      boxShadow: theme?.palette?.background?.toggleShadow,
      borderRadius: "12px !important",
      fontStyle: "normal",

      textTransform: "capitalize",
      color: "#FFFFFF",
      padding: "5px 16px !important",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-root": {
      minHeight: "inherit",
    },
    "& .MuiTabs-flexContainer": {
      gap: "15px",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    "& .MuiTabs-scroller, & .MuiTabs-root": {
      overflow: "visible !important",
    },
  },
  noBetsPara: {
    position: "relative",
    margin: "0",
    color: "#4D4D5B",
    padding: "10px 10px 20px",
    fontSize: "22px",
    fontWeight: "600",
    textAlign: "center",
  },
  tableBg: {
    background: theme?.palette?.background?.mobiTableBg,
    boxShadow: theme?.palette?.background?.mobiTableShadow,
    borderRadius: "8px",
    marginBottom: "40px",
    marginTop: "40px",
    display: "none",
    [theme.breakpoints.down(991)]: {
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0px 40px",
    },
  },

  mobileTable: {
    padding: "6px 10px",
    [theme.breakpoints.down("sm")]: {
      padding: "6px 10px",
    },
    "&:nth-child(even)": {
      background: theme?.palette?.background?.mobiTableOddBg,
    },
    "&:last-child": {
      borderRadius: "0 0 8px 8px",
    },
  },

  mobileTableDetail: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& p": {
      margin: "0",
    },
  },
  userImage: {
    height: "40px",
    width: "40px",
    position: "relative",
    cursor: "pointer",
    marginTop: "-8px",
    "& svg": {
      stroke: theme.palette.background?.userImgBorder,
    },
    "& img": {
      width: "100%",
      clipPath:
        "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
      objectFit: "cover",
      aspectRatio: "1",
      borderRadius: "4px",
      maxHeight: "40px",
      maxWidth: "40px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  userBorder: {
    width: "100%",
    clipPath:
      "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
    objectFit: "cover",
    aspectRatio: "1",
    borderRadius: "4px",
    maxHeight: "38px",
    maxWidth: "38px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background?.userImgBorder,
    border: "2px solid ",
    borderColor: theme.palette.background?.userImgBorder,
  },

  userProfileLevel: {
    position: "absolute",
    background: theme.palette.background?.chatTagBg,
    border: theme.palette.background?.chatTagBorder,
    boxShadow: theme.palette.background?.chatTagShadow,
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "17px",
    textTransform: "capitalize",
    color: theme.palette.text?.default,
    padding: "0px 9px",
    left: "3px",
    bottom: "-13px",
    width: "100%",
    minWidth: "32px",
    maxWidth: "32px",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
      bottom: "-5px",
      minWidth: "24px",
      maxWidth: "24px",
      left: "50%",
      height: "12px",
      fontWeight: "600",
      transform: "translateX(-50%)",
      padding: "0px 2px",
      borderRadius: "2px",
      marginLeft: "-1px",
    },
  },

  gameName: {
    flexGrow: "1",
    "& p": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "25px",
      textTransform: "capitalize",
      color: theme?.palette?.text?.mobiTableText_2,
      "&:nth-child(1)": {
        color: theme?.palette?.text?.mobiTableText_1,
      },
      [theme.breakpoints.down("sm")]: {
        lineHeight: "18px",
      },
    },
  },

  amountSection: {
    display: "flex",
    alignItems: "center",
  },

  currencyStatus: {
    width: "60px",
    "& p": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "12px",
      lineHeight: "25px",
      textTransform: "capitalize",
      color: theme?.palette?.text?.mobiTableText_1,
      [theme.breakpoints.down("md")]: {
        lineHeight: "18px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
    },
  },

  currencySection: {
    "& p": {
      display: "flex",
      alignItems: "center",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "18px",
      justifyContent: "flex-end",
      color: theme?.palette?.text?.mobiTableText_2,
      minWidth: "70px",
      "& span": {
        width: "20px",
        height: "20px",
        display: "flex",
        position: "relative",
        marginLeft: "5px",
      },
      "& img": {
        position: "absolute",
        width: "21px",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      },
      "& svg": {
        top: "56%",
        left: "50%",
        width: "20px",
        height: "20px",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      },
    },
  },

  betTableWrap: {
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down("xl")]: {
      position: "relative",
    },
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "unset",
    },
  },
  imageHover: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(1px)",
    background: theme?.palette?.background?.hoverBg,
    borderTop: theme?.palette?.background?.gameTopBorder,
    top: "0",
    transition: "top 0.8s",
    left: "0",
    textAlign: "center",
    bottom: "0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "0",
    opacity: "0",
    "& img": {
      width: "80px",
      height: "80px",
      objectFit: "contain",
      margin: "0 auto",
      borderTop: "none",
      transform: "inherit !important",
      [theme.breakpoints.down("md")]: {
        width: "60px",
        height: "60px",
      },
    },
    "& p": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: theme?.palette?.text?.default,
      margin: "0",
      [theme.breakpoints.down("md")]: {
        fontSize: "12px",
        lineHeight: "14px",
      },
    },

    "& .favBtnClass": {
      position: "absolute",
      top: "10px",
      right: "10px",
      padding: "0 !important",
      justifyContent: "flex-end",
      minWidth: "inherit !important",
      [theme.breakpoints.down("md")]: {
        top: "0px",
        right: "5px",
      },
      "& svg": {
        color: `${theme?.palette?.text?.default} !important`,
        [theme.breakpoints.down("md")]: {
          width: "0.9rem",
        },
      },
    },
    "& .playBtn": {
      flexDirection: "column",
      padding: "0 !important",
      width: "100%",
    },
  },
  cryptoSection: {
    padding: "40px 20px 40px 20px",
    width: "100%",
    display: "flex",
    [theme.breakpoints.down(1200)]: {
      padding: "40px 10px 0 10px",
    },
    [theme.breakpoints.down(1200)]: {
      padding: "20px 0px 0 0px",
    },
    [theme.breakpoints.down(768)]: {
      padding: "20px 12px",
      alignItems: "center",
      flexWrap: "wrap",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px",
    },
    "& ul": {
      padding: "0",
      listStyle: "none",
      "& li": {
        padding: "0",
        display: "flex",
        [theme.breakpoints.down(575)]: {
          textAlign: "left",
        },
        [theme.breakpoints.between(991, 1199)]: {
          flexDirection: "column",
          textAlign: "center",
        },
      },
    },
    "& .MuiGrid-item": {
      paddingTop: "0",
      paddingBottom: "0",
    },
  },

  cryptoText: {
    float: "left",
    width: "313px",
    [theme.breakpoints.down(1536)]: {
      width: "130px",
    },
    [theme.breakpoints.down(1200)]: {
      width: "120px",
    },

    [theme.breakpoints.down(991)]: {
      width: "220px",
    },

    [theme.breakpoints.down(769)]: {
      width: "220px",
    },

    [theme.breakpoints.down(575)]: {
      width: "230px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "208px",
    },
    "& ul": {
      display: "flex",
      "& span": {
        "&:nth-child(1)": {
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "40px",
          lineHeight: "58px",
          textTransform: "capitalize",
          color: "#757786",
          paddingRight: "12px",
          [theme.breakpoints.down("xl")]: {
            fontSize: "22px",
            lineHeight: "28px",
          },
          [theme.breakpoints.down(1200)]: {
            fontSize: "28px",
            lineHeight: "30px",
            paddingRight: "5px",
          },
          [theme.breakpoints.down("sm")]: {
            lineHeight: "28px",
          },
        },
        "&:nth-child(2)": {
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "14px",
          lineHeight: "20px",
          textTransform: "capitalize",
          color: "#757786",
          maxWidth: "40px",
          display: "inline-block",
          [theme.breakpoints.down("xl")]: {
            lineHeight: "15px",
            fontSize: "14px",
          },
          [theme.breakpoints.down("sm")]: {
            lineHeight: "12px",
            fontSize: "12px",
          },
        },
        [theme.breakpoints.down(1200)]: {
          fontSize: "12px",
        },
      },
      "& li": {
        [theme.breakpoints.between(1199, 1536)]: {
          flexDirection: "column",
        },
        "&:nth-child(2)": {
          [theme.breakpoints.down(575)]: {
            borderRight: "1px solid #2E2E37",
            borderRadius: "2px",
            marginRight: "10px",
          },
        },
      },
    },
  },

  cryptoLogo: {
    borderLeft: theme.palette?.background?.cryprosectionBorder,
    borderRight: theme.palette?.background?.cryprosectionBorder,
    borderRadius: "2px",
    paddingLeft: "35px",
    paddingRight: "35px",
    float: "left",
    width: "calc(100% - 413px)",
    minHeight: "58px",
    [theme.breakpoints.down(1536)]: {
      width: "calc(100% - 190px)",
      paddingLeft: "0",
      paddingRight: "0px",
    },
    [theme.breakpoints.down(1200)]: {
      width: "calc(100% - 180px)",
      paddingLeft: "0",
      paddingRight: "0px",
    },
    [theme.breakpoints.down(991)]: {
      minHeight: "30px",
      width: "calc(100% - 330px)",
    },
    [theme.breakpoints.down(769)]: {
      width: "calc(100% - 330px)",
      minHeight: "inherit",
    },
    [theme.breakpoints.down(575)]: {
      width: "calc(100% - 230px)",
      border: "none",
      minHeight: "inherit",
      display: "inline-block",
    },
    "& ul": {
      "& li": {
        textAlign: "center",
        "& img": {
          width: "80px",
          margin: "0 auto",
          objectFit: "contain",
        },
      },
    },
  },

  showAll: {
    whiteSpace: "nowrap",
    lineHeight: "58px",
    minHeight: "58px",
    float: "left",
    width: "100px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down(1536)]: {
      width: "60px",
    },
    [theme.breakpoints.down(991)]: {
      minHeight: "30px",
    },
    [theme.breakpoints.down(769)]: {
      minHeight: "inherit",
      width: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& div": {
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: "#757786",
      textAlign: "right",
      [theme.breakpoints.down("xl")]: {
        fontSize: "14px",
      },
    },
  },
  mblPayment: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "grid",
      gridTemplateColumns: "repeat(3,minmax(0,1fr))",
      gap: "20px 30px",
      width: "100%",
      marginTop: "20px",
      borderTop: "1px solid rgba(46, 46, 55, 0.44)",
      paddingTop: "20px",
    },
  },
  mblPaymentBox: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      maxWidth: "65px",
    },
  },
  gameIcon: {
    display: "flex",
    alignItems: "center",
    "& img": {
      marginRight: "12px",
      width: "15px",
    },
  },

  playerIcon: {
    display: "flex",
    alignItems: "center",
    color: theme?.palette?.text?.default,
    "& span": {
      "& img": {
        marginRight: "12px",
        width: "100%",
        clipPath: "polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)",
        objectFit: "cover",
        aspectRatio: "1",
        borderRadius: "4px",
        maxHieght: "5px",
        maxWidth: "30px",
      },
    },
  },

  betIcon: {
    display: "flex",
    alignItems: "center",
    color: theme?.palette?.text?.default,
    justifyContent: "center",
    "& img": {
      marginRight: "12px",
    },
    "& span": {
      display: "inherit",
      width: "28px",
    },
  },

  greenColor: {
    color: "#6CD9A5",
  },

  redColor: {
    color: "#F25C5C",
  },

  tabSection: {
    borderRadius: "12px 12px 0 0",
    padding: "24px 0 0px 40px",
    "& .MuiTabs-flexContainer": {
      gap: "28px",
    },
    "& .Mui-selected": {
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      border: "1.4px solid rgba(106, 113, 199, 0.84)",
      boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
      borderRadius: "12px !important",
      color: "#FFFFFF !important",
      padding: "0 25px !important",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& button": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: theme?.palette?.text?.color,
      borderRadius: "12px !important",
      margin: "0",
      padding: "0px !important",
      minHeight: "32px",
      minWidth: "inherit",
    },
    "& .MuiTabs-scroller, & .MuiTabs-root": {
      overflow: "visible !important",
    },
  },

  tabSectionTransaction: {
    borderRadius: "10px 10px 0 0",
    width: "100%",
    "& .Mui-selected": {
      background: "#2B2B38",
      borderRadius: "10px !important",
      color: "#FFFFFF !important",
      padding: "12px 0px !important",
      border: "1px solid #353542",
      [theme.breakpoints.down("767")]: {
        borderRadius: "8px !important",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& button": {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "capitalize",
      color: theme?.palette?.text?.color,
      borderRadius: "12px !important",
      margin: "0",
      padding: "0px !important",
      minHeight: "32px",
      minWidth: "25%",
      height: "50px",
      [theme.breakpoints.down("767")]: {
        fontSize: "12px",
        height: "32px !important",
        lineHeight: "23px",
      },
    },
    "& .MuiTabs-scroller, & .MuiTabs-root": {
      overflow: "visible !important",
    },
  },

  mobSearch: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: "20px",
    },
  },
  modalFavBtn: {
    margin: "0 2px",
    "& .MuiButtonBase-root ": {
      fontSize: "8px",
      background: theme?.palette?.primary1?.color,
      width: "100%",
      padding: "4px 5px",
      color: "#fff",
      borderRadius: "2px",
      "& img": {
        width: "10px",
        marginLeft: "5px",
      },
      "&:hover": {
        background: theme?.palette?.primary1?.color,
        color: "#fff",
      },
    },
  },

  noBets: {
    width: "100%",
    textAlign: "center",
    color: `${theme?.palette?.text?.default} !important`,
    fontSize: "22px !important",
  },
  lobbyPage: {
    position: "relative",
    marginTop: "80px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "72px",
    },
  },

  transLobbyPage: {
    position: "relative",
    marginTop: "80px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "59px",
      backgroundSize: "120%",
      backgroundPosition: "center -13px",
    },
  },

  nonLoggedInUser: {
    position: "relative",
    padding: "120px 0px 120px",
    backgroundImage: "url(images/bg-overlay.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top",
    [theme.breakpoints.down("sm")]: {
      padding: "20px 0px 40px",
      display: "none",
    },
    "& h2": {
      fontSize: "42px",
      fontWeight: "800",
      lineHeight: "32px",
      textTransform: "uppercase",
      textAlign: "center",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        fontSize: "18px",
        lineHeight: "20px",
      },
      "& span": {
        color: "#00DAEA",
      },
    },
    "& p": {
      color: "#757786",
      textAlign: "center",
      fontSize: "26px",
      fontWeight: "600",
      lineHeight: "160%",
      padding: "32px 0px 40px",
      maxWidth: "570px",
      margin: "0px auto",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "10px 0px 20px",
      },
      "& span": {
        color: "#00DAEA",
      },
    },
    "& button": {
      borderRadius: "12px",
      border: "2px solid rgba(106, 113, 199, 0.84)",
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      boxShadow: "0px 4px 24px 2px rgba(108, 93, 193, 0.24)",
      color: "#fff",
      maxWidth: "300px",
      width: "100%",
      height: "60px",
      margin: "0px auto",
      display: "block",
      fontSize: "18px",
      fontWeight: "800",
      lineHeight: "22px",
      textTransform: "capitalize",
      fontFamily: "Jost !important",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "160px",
        height: "40px",
        fontSize: "14px",
        fontWeight: "800",
        lineHeight: "22px",
      },
    },
  },
}));
